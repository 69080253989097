// Dashboard.tsx
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext"; // Adjust the path as necessary
import "./Dashboard.css"; // Import a CSS file for styles
import { Card, CardContent, Typography, Button, ButtonGroup } from "@mui/material";

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const roles = user?.roles || {};

  // Navigation handler function
  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <div className="dashboard-container">
      {Object.keys(roles).some(
        (businessId) => roles[businessId]?.length > 0
      ) && (
        <Card sx={{ maxWidth: 400, margin: "auto", mt: 4 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom className="estimator-title">
              Staff Resources
            </Typography>
            <ButtonGroup
            
              orientation="vertical"
              variant="contained"
              color="primary"
              fullWidth
            >
              <Button
              disabled
              size="large"
              onClick={() => handleNavigation("/treatment-estimator")}
              >
              Laser Estimate
              </Button>
              <Button
              size="large"
              onClick={() => handleNavigation("/go")}
              sx={{ mt: 2 }}
              >
              Go Links
              </Button>
            </ButtonGroup>

            {/* <Button
            variant="contained"
            color="primary"
            onClick={() => handleNavigation("/treatment-estimator")}
          >
            EMS Estimate
          </Button> */}
          </CardContent>
        </Card>
      )}

      {/* <h1>          Get Your Personalized Estimate Today!
      </h1> */}
      <Card sx={{ maxWidth: 400, margin: "auto", mt: 4 }}>
        <CardContent>
          <Typography variant="h5" component="div">
            Get Your Laser Hair Removal Estimate!
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            Ready to say goodbye to unwanted hair? Fill out the form below, and
            our team will provide you with a personalized estimate for laser
            hair removal based on your specific needs. Start your journey to
            smooth, hair-free skin today!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => handleNavigation("/treatment-estimator")}
          >
            Get Started
          </Button>
        </CardContent>
      </Card>
      {/* 
      <Button
        variant="outlined"
        color="primary"
        size="large"
        onClick={() => handleNavigation("/treatment-estimator")}
      >
        Laser Estimate
      </Button> */}

      {/* if no business role, state that */}
      {/* {Object.keys(roles).length === 0 && (
        <p>You do not have any business roles.</p>
      )} */}

      {/* Display business roles */}

      {/* Conditional Display for Estimator Tools */}
    </div>
  );
};

export default Dashboard;
