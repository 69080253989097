

import { TreatmentArea } from "./types";

const areasPerSize: { [key: string]: TreatmentArea[] } = {
  small: [
    { english: "Back of the neck", portuguese: "Parte de trás do pescoço", price: 75, bodyArea: "Face & Neck" },
    { english: "Front of the neck", portuguese: "Parte da frente do pescoço", price: 75, bodyArea: "Face & Neck" },
    { english: "Upper Lip", portuguese: "Buço", price: 75, bodyArea: "Face & Neck" },
    { english: "Chin", portuguese: "Queixo", price: 75, bodyArea: "Face & Neck" },
    { english: "Abdomen line", portuguese: "Linha do abdômen", price: 75, bodyArea: "Upper Body" }
  ],
  medium: [
    { english: "Underarms", portuguese: "Axilas", price: 100, bodyArea: "Upper Body" },
    { english: "Upper Lip and Chin", portuguese: "Buço e Queixo", price: 100, bodyArea: "Face & Neck" },
    { english: "Full face/beard", portuguese: "Rosto completo/barba", price: 100, bodyArea: "Face & Neck" },
    { english: "Partial bikini", portuguese: "Virilha parcial", price: 100, bodyArea: "Lower Body" },
    { english: "Lower arms", portuguese: "Braços inferiores", price: 100, bodyArea: "Upper Body" },
    { english: "Upper arms", portuguese: "Braços superiores", price: 100, bodyArea: "Upper Body" },
  ],
  large: [
    { english: "Full bikini [Brazilian]", portuguese: "Virilha completa", price: 150, bodyArea: "Lower Body" },
    { english: "Buttocks", portuguese: "Nádegas", price: 150, bodyArea: "Lower Body" },
    { english: "Upper legs", portuguese: "Pernas superiores", price: 150, bodyArea: "Lower Body" },
    { english: "Lower legs", portuguese: "Pernas inferiores", price: 150, bodyArea: "Lower Body" },
    { english: "Full arms", portuguese: "Braços completos", price: 150, bodyArea: "Upper Body" },
    { english: "Lower back", portuguese: "Parte inferior das costas", price: 150, bodyArea: "Upper Body" },
    { english: "Upper back", portuguese: "Parte superior das costas", price: 150, bodyArea: "Upper Body" },
    { english: "Abdomen", portuguese: "Abdômen", price: 150, bodyArea: "Upper Body" },
    { english: "Chest", portuguese: "Peito", price: 150, bodyArea: "Upper Body" },
  ],
  extraLarge: [
    { english: "Full legs", portuguese: "Pernas completas", price: 300, bodyArea: "Lower Body" },
    { english: "Full back", portuguese: "Costas completas", price: 300, bodyArea: "Upper Body" },
    { english: "Chest and abdomen", portuguese: "Peito e abdômen", price: 300, bodyArea: "Upper Body" }
  ]
};

const transformByBodyArea = (areas: { [key: string]: TreatmentArea[] }) => {
  const result: { [bodyArea: string]: TreatmentArea[] } = {};

  for (const size in areas) {
    areas[size].forEach(area => {
      if (!result[area.bodyArea]) {
        result[area.bodyArea] = [];
      }
      result[area.bodyArea].push({
        english: area.english,
        portuguese: area.portuguese,
        price: area.price,
        bodyArea: area.bodyArea});
    });
  }

  return result;
};

const areasByBodyArea = transformByBodyArea(areasPerSize);
// console.log(areasByBodyArea);

const areas = areasByBodyArea
export { areas };