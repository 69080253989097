// Settings.tsx
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { useAuth } from "../context/AuthContext"; // Adjust the path as necessary
import { hasRole, hasBusinessRole } from "../utils/roleUtils";
import { Role } from "../types/roles";
import './Settings.css'; // Import a CSS file for styles

const Settings: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const roles = user?.roles || {};

  // Navigation handler function
  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const handleSelectBusiness = (businessId: string) => {
    // Navigate to the business page with the selected business ID
    // set local storage to store the selected business ID
    localStorage.setItem("selectedBusinessId", businessId);
    // show alert message
    alert(`Selected Business Id: ${businessId}`);
  }

  // Function to render role-based buttons
  const renderRoleButtons = (businessId: string, role: string) => (
    <Button
      key={role}
      variant="text"
      color="primary"
      className="role-button"
      onClick={() => handleSelectBusiness(businessId)}
    >
      {role}
    </Button>
  );

  return (
    <div className="settings-container">
      <Typography variant="h4" gutterBottom>
        User Settings
      </Typography>

      {/* add text explaining the purpose of this page */}
      <Typography variant="body1" gutterBottom>
        This page displays the roles assigned to the user.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Click on the buttons below to navigate to the respective pages.
      </Typography>


      {/* Customer Portal Button */}
      {hasRole(user, Role.Customer) && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleNavigation("/customer-portal")}
          className="action-button"
        >
          Go to Customer Portal
        </Button>
      )}
      <br /> <br />
      {/* Staff Page Button */}
      {hasRole(user, Role.Staff) && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleNavigation("/business-admin")}
          className="action-button"
        >
          Go to Staff Page
        </Button>
      )}
      <br /> <br />
      {/* Platform Admin Page Button */}
      {hasBusinessRole(user, "platform", Role.Admin) && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleNavigation("/platform-admin")}
          className="action-button"
        >
          Go to Platform Admin Page
        </Button>
      )}

      {/* Error Message for No Assigned Roles */}
      {Object.keys(roles).length === 0 && (
        <Typography variant="body1" color="error">
          You do not have any assigned roles. ID: {user?.id}
        </Typography>
      )}

      {/* Display Roles for each Business ID */}
      {Object.keys(roles).map((businessId) => (
        <div key={businessId} className="business-roles">
          <Typography variant="body1">
            Business ID: {businessId}
          </Typography>
          {Array.isArray(roles[businessId]) ? (
            roles[businessId].map((role) => renderRoleButtons(businessId, role))
          ) : (
            <Typography variant="body2" color="error">
              Invalid roles for business ID: {businessId}
            </Typography>
          )}
        </div>
      ))}
    </div>
  );
};

export default Settings;
