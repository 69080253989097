import React, { useState, useEffect } from "react";
import axios from "axios";
import { TextField, Button, Typography, Box, Alert, List, ListItem, ListItemText } from "@mui/material";

const ShortCodeMaker: React.FC = () => {
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [shortCode, setShortCode] = useState("");
  const [error, setError] = useState("");
  const [shortCodes, setShortCodes] = useState<{ short_code: string, original_url: string }[]>([]);

  useEffect(() => {
    // Fetch the list of shortcodes when the component mounts
    const fetchShortCodes = async () => {
      try {
        const response = await axios.get("/api/shortcodes");
        setShortCodes(response.data);
      } catch (error: any) {
        console.error("Error fetching short codes:", error);
        setError("Error fetching short codes.");
      }
    };

    fetchShortCodes();
  }, []);

  const handleCreateShortCode = async () => {
    try {
      const response = await axios.post("/api/shorten", { originalUrl: url, name });
      setShortCode(response.data.shortCode);
      setError(""); // Clear any previous errors
      // Fetch the updated list of shortcodes
      const updatedShortCodes = await axios.get("/api/shortcodes");
      setShortCodes(updatedShortCodes.data);
    } catch (error: any) {
      if (error.code === "ERR_BAD_REQUEST") {
        setError("Code already exists.");
      } else {
        console.error("Error creating short code:", error);
        setError("Error creating short code.");
      }
    }
  };

  return (
    <Box sx={{ maxWidth: 400, mx: "auto", mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Create Short Code
      </Typography>
      <TextField
        fullWidth
        label="Enter First Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        variant="outlined"
        margin="normal"
      />
      <TextField
        fullWidth
        label="Enter URL"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        variant="outlined"
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateShortCode}
        fullWidth
        sx={{ mt: 2 }}
      >
        Create
      </Button>
      {shortCode && (
        <Box mt={2}>
          <Typography variant="h6">Short Code: {shortCode}</Typography>
          <Typography variant="body2">
            Full URL: <a href={`https://app.chanramos.com/go/${shortCode}`}>{`https://app.chanramos.com/go/${shortCode}`}</a>
          </Typography>
        </Box>
      )}
      {error && (
        <Box mt={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          Short Codes List
        </Typography>
        <List>
          {shortCodes.map((item, index) => (
            <ListItem key={index}>
              <ListItemText
              primary={
                <>
                Short Code: {item.short_code}
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigator.clipboard.writeText(`https://app.chanramos.com/go/${item.short_code}`)}
                  sx={{ ml: 2 }}
                >
                  Copy
                </Button>
                </>
              }
              secondary={
                <>
                <a href={`https://app.chanramos.com/go/${item.short_code}`} target="_blank" rel="noopener noreferrer">
                  {`https://app.chanramos.com/go/${item.short_code}`}
                </a>
                <br />
                Original URL: {item.original_url}
                </>
              }
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default ShortCodeMaker;