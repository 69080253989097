import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ShortCodeForwarder: React.FC = () => {
    const { shortCode } = useParams<{ shortCode: string }>();
    const [originalUrl, setOriginalUrl] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchOriginalUrl = async () => {
            console.log('[fetchOriginalUrl] start. Short code:', shortCode);

            if (shortCode) {
                try {
                    const response = await fetch(`/api/go/${shortCode}`);
                    if (response.ok) {
                        const data = await response.json();
                        console.log('[fetchOriginalUrl] Original URL:', data.originalUrl);
                        setOriginalUrl(data.originalUrl);
                    } else {
                        console.error('Error fetching the original URL:', response.statusText);
                        setError('Short code not found');
                    }
                } catch (err) {
                    console.error('Caught Error fetching the original URL:', err);
                    setError('Error fetching the original URL');
                }
            }
        };

        fetchOriginalUrl();
    }, [shortCode]);

    useEffect(() => {
        console.log('[useEffect] with', originalUrl);
        if (originalUrl) {
            window.location.href = originalUrl;
        }
    }, [originalUrl]);

    return (
        <div>
            <h1>Redirecting...</h1>
            {error ? (
                <p>{error}</p>
            ) : (
                <p>If you are not redirected automatically, <a href={originalUrl || '#'}>click here</a>.</p>
            )}
        </div>
    );
};

export default ShortCodeForwarder;